<template>
  <!-- 关键词 文章列表 -->
  <div class="container">
    <!-- 内容 -->
    <div class="center">
      <div class="content">
        <!-- 快讯详细信息 -->
        <div class="left">
            <!-- 文章列表 -->
            <div class="list">
              <V-ArticleList :newarticlelist="newarticlelist" ></V-ArticleList>
            </div>
            <br/>
            <!-- 分页 -->
            <div class="page-nav">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :hide-on-single-page="hidePageNav"
                    :total="totlePage"
                    :page-size="pageSize"
                    @prev-click="prevPage" @next-click="nextPage"  @current-change="currentPage">
                </el-pagination>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleList from "@/components/one-article/type-one/index.vue";
import {DELETE,QUERY}  from '@/services/dao.js'
export default {
  data() {
    return {
        id:-1,
      hidePageNav:true,
      pageNum:0,
      pageSize:12,
      totlePage:0,
      newarticlelist:[],
      status:1 //1-人气榜 2-综合榜 3-收藏榜
    };
    
  },
  created: function(){
      this.init();
  },
  methods:{
    init(){
      this.id=this.$route.query.id;
      this.searchInfo(this.pageSize,0);
    },
       //前一页
    prevPage(val){
        this.pageNum=val-1;
        this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
    },
    //下一页
    nextPage(val){
        this.pageNum=val-1;
        this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
    },
    //当前页
    currentPage(val){
        this.pageNum=val-1;
        this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
    },
    changeHostList(status){//切换热榜
      this.status=status;
      this.pageNum=0;
      this.totlePage=0;
      this.searchInfo(this.pageSize,0);
    }
    ,
    async  searchInfo(limit,offset){
        let info=null;
        info=  await  QUERY("post","",'SortCollect(where: {sort_id: {_eq: '+this.id+'}}, limit: '+limit+', offset: '+offset+') {    HeadlinesCollect {      Article {        id        createTime        content        concernNum        commentNum        collectNum        browseNum        accessory        img        intro        keyword        praiseNum        reference        theme_id        title        type        hotNum        authorName        authorId        recommendNum      }    }  }  SortCollect_aggregate(where: {sort_id: {_eq: '+this.id+'}}) {    aggregate {      count    }  }');
       if(this.newarticlelist.length){
         this.newarticlelist.splice(0,this.newarticlelist.length);
       }
        for(let i=0;i<info.data.SortCollect.length;i++){
            this.newarticlelist.push({
              title:info.data.SortCollect[i].HeadlinesCollect.Article.title,
              intro:info.data.SortCollect[i].HeadlinesCollect.Article.intro,
              name:info.data.SortCollect[i].HeadlinesCollect.Article.authorName,
              time:info.data.SortCollect[i].HeadlinesCollect.Article.createTime,
              collectNum:info.data.SortCollect[i].HeadlinesCollect.Article.collectNum,
              userId:2,
              commentNum:info.data.SortCollect[i].HeadlinesCollect.Article.commentNum,
              image:info.data.SortCollect[i].HeadlinesCollect.Article.img,
              id:info.data.SortCollect[i].HeadlinesCollect.Article.id,
              hotNum:info.data.SortCollect[i].HeadlinesCollect.Article.hotNum,
              praiseNum:info.data.SortCollect[i].HeadlinesCollect.Article.praiseNum,
              concernNum:info.data.SortCollect[i].HeadlinesCollect.Article.concernNum,
              keyword:info.data.SortCollect[i].HeadlinesCollect.Article.keyword
            });
        }
        this.totlePage=info.data.SortCollect_aggregate.aggregate.count;
    }
  },
  components:{
      'V-ArticleList':ArticleList
  }
}
</script>

<style scoped>
@import url(~@/styles/page/index.css);
.container {
  text-align: center;
  background-color: #F7F7F7;
}
.container> .center{
    margin-top: 30px;
    width: 100%;
    padding-bottom:30px ;
}

.container > .center > .content {
  width: 1190px;
  display: inline-block;
  text-align: center;
  position: relative;
}
.container > .center > .content > .left {
  display: inline-block;
  width: 750px; 
  background-color: #FFFFFF;
}
.container > .center > .content > .right {
  display: inline-block;
  width: 330px;
  vertical-align: top;
  margin-left: 30px;
  text-align: center;
   background-color: #FFFFFF;
   height: 250px;
}
.container > .center > .content > .right >div{
  display: inline-block;
}
</style>